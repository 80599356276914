<template>
  <div ref="root" class="date-root-container">
    <span class="date-header">{{title}}</span>
    <DatePicker
      :value="value"
      :type="type"
      :placeholder="placeholder"
      style="width:100%"
      @on-change="change"
    />
    <div class="date-error-container">
      <transition name="fade">
        <Tooltip
          max-width="300"
          :disabled="toolTipDisabled"
          :content="errorText"
          style="max-width:100%"
        >
          <span ref="error" v-if="showError" class="date-error">{{errorText}}</span>
        </Tooltip>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    validate: Function,
    value: {
      type: [Date, String, Array]
    },
    type: {
      type: String,
      default: "date"
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showError: false,
      errorText: "",
      toolTipDisabled: true
    };
  },
  mounted() {},
  watch: {
    errorText: function(newValue, oldValue) {
      if (newValue == "") {
        this.toolTipDisabled = true;
      } else {
        this.$nextTick(() => {
          if (this.$refs.error.offsetWidth >= this.$refs.root.offsetWidth) {
            this.toolTipDisabled = false;
          } else {
            this.toolTipDisabled = true;
          }
        });
      }
    }
  },
  methods: {
    change(format, type) {
      // console.log(format, type);
      if (format == "") {
        this.$emit("input", format);
      } else {
        this.$emit("input", new Date(format));
      }
    },
    doValidate() {
      return new Promise((resolve, reject) => {
        if (this.validate) {
          this.validate(this.value)
            .then(res => {
              this.errorText = "";
              this.showError = false;
              resolve();
            })
            .catch(error => {
              this.errorText = error;
              this.showError = true;
              reject();
            });
        } else {
          resolve();
        }
      });
    },
    clearValidate() {
      this.errorText = "";
      this.showError = false;
    }
  }
};
</script>

<style>
.date-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.date-header {
  font-weight: bold;
  margin-bottom: 10px;
}
.date-error-container {
  height: 14px;
  margin-top: 4px;
}
.date-error {
  display: block;
  color: #f60;
  font-size: 12px;
  line-height: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date-error-container .ivu-tooltip-rel {
  display: block;
  max-width: 100%;
}
</style>