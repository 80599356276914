<template>
  <div class="accessories-item-root-container" :title="dataSource.file_name">
    <img :src="this.fileIcon" style="width:32px;height:32px;margin:0px 10px;" />
    <div style="display:flex;flex-direction:column;align-items:stretch;overflow:hidden;">
      <a :href="dataSource.file_url" class="accessories-item-title">{{ dataSource.file_name }}</a>
      <div style="font-size: 12px;font-weight: 500;color: #4E4E4E;">{{ fileSize }}</div>
    </div>
  </div>
</template>

<script>
import * as icon from "../../common/icon";
import { changeUnit } from "../../utils/ossHelper";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: icon,
      fileIcon: {},
      fileSize: "",
    };
  },
  mounted() {
    let exts = this.dataSource.file_name.split(".");
    let extname = exts[exts.length - 1];
    this.fileIcon = this.icons.other;
    if (extname == "doc" || extname == "docx") {
      this.fileIcon = this.icons.doc;
    } else if (extname == "xls" || extname == "xlsx") {
      this.fileIcon = this.icons.xls;
    } else if (extname == "pdf") {
      this.fileIcon = this.icons.pdf;
    }

    this.fileSize = changeUnit(this.dataSource.size);
  },
};
</script>

<style scoped>
.accessories-item-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 170px;
  min-height: 50px;
  background-color: #e7edf5;
  border-radius: 8px;
  cursor: pointer;
}
.accessories-item-title {
  font-size: 15px;
  font-weight: bold;
  color: #5b87bb;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>