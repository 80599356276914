<template>
  <div class="project-root-container">
    <div class="project-header-container">
      <div class="project-header-left-container">
        <img :src="icons.projectLogo" />
        <span class="project-header-title">发布一个项目/工作</span>
      </div>
      <img @click="close" :src="icons.upArrow" class="project-arrow" />
    </div>
    <div class="project-main-container">
      <div
        class="project-background"
        :style="{ 'background-image': 'url(' + icons.employerBackground + ')' }"
      ></div>
      <div class="project-scroll-wrapper">
        <div class="project-form-container">
          <span style="font-size: 32px; font-weight: bold; color: #ffffff"
            >发布一个项目</span
          >
          <p style="font-size: 15px; color: #ffffff; margin-top: 20px">
            通过游用工平台与工作者建立联系，邀请或等待对方的要约，创建多种任务，组建团队，并在工作成果100%满意时向对方付款。简单、高效、合规、安全👍
          </p>

          <div class="project-form-main-container">
            <span class="project-title">命名该项目</span>
            <span class="project-desc"
              >如果对命名不满意，稍后您可以对其进行修改</span
            >
            <my-input
              ref="formName"
              v-model="projectForm.name"
              placeholder="如：XXXXXX"
              title
              size="large"
              :validate="nameValidate"
            />
            <Divider />
            <span class="project-title">关联公司实体</span>
            <Select
              v-model="projectForm.company_id"
              size="large"
              style="width: 300px; margin-top: 20px; margin-bottom: 20px"
            >
              <Option
                v-for="item in companyList"
                :value="item.company_id"
                :key="item.company_id"
                >{{ item.name }}</Option
              >
            </Select>
            <Divider />
            <span class="project-title">更多信息</span>
            <div class="project-row">
              <span class="project-desc"
                >添加有关该项目，或者您公司自身更多的信息</span
              >
              <span class="project-desc">为您的项目添加一个logo</span>
            </div>
            <div class="project-row">
              <my-textarea
                title
                :rows="8"
                style="flex: 1"
                size="large"
                ref="formDesc"
                v-model="projectForm.more"
              />
              <my-single-image
                class="project-form-image"
                ref="formImage"
                :before-upload="handleBeforeUploadImg"
                :img-url="projectForm.img_url"
              ></my-single-image>
            </div>
            <Divider />
            <span class="project-title">添加技能的描述</span>

            <div class="project-tag-container">
              <div
                v-for="(item, index) in projectForm.tags"
                :key="index"
                class="project-tag"
              >
                <Icon type="ios-pricetag" />
                <span style="margin-left: 5px; margin-right: 15px">{{
                  item
                }}</span>
                <Icon type="md-close" @click="deleteTag(index)" />
              </div>
            </div>
            <Button
              type="text"
              style="color: #3b77bf; width: 28px; padding: 0"
              @click="addTag"
              >添加</Button
            >

            <Divider />
            <span class="project-title"
              >项目的起止日期以及工作地点（非必选）</span
            >
            <span class="project-desc"
              >您还可以通过任务中心来细化定义时间与地点</span
            >

            <span class="project-sub-title">起止日期</span>
            <div class="project-time-container">
              <span style="margin-right: 20px">自</span>
              <DatePicker
                :value="projectForm.start_time"
                format="yyyy年MM月dd日"
                type="date"
                style="width: 150px"
              ></DatePicker>
              <span style="margin-left: 20px; margin-right: 20px">至</span>
              <DatePicker
                :value="projectForm.end_time"
                format="yyyy年MM月dd日"
                type="date"
                style="width: 150px"
              ></DatePicker>
            </div>

            <span class="project-sub-title" style="margin-top: 40px">地址</span>
            <div class="project-tag-container">
              <div
                v-for="(item, index) in projectForm.address_arr"
                :key="index"
                class="project-tag"
              >
                <Icon type="md-pin" />
                <span style="margin-left: 5px; margin-right: 15px">{{
                  item
                }}</span>
                <Icon type="md-close" @click="deleteAddress(index)" />
              </div>
            </div>
            <Button
              type="text"
              style="color: #3b77bf; width: 28px; padding: 0"
              @click="addAddress"
              >添加</Button
            >

            <Divider />
            <span class="project-title">项目包含的付款方式（可多选）</span>

            <div class="project-type-container">
              <div
                class="project-type-item"
                @click="
                  () => {
                    projectForm.pay_type.fixed = !projectForm.pay_type.fixed;
                  }
                "
              >
                <img :src="icons.fixed" />
                <span style="font-size: 12px; color: #424242; margin: 0px 20px"
                  >固定金额</span
                >
                <Icon
                  :size="20"
                  v-show="projectForm.pay_type.fixed == true"
                  type="ios-checkmark"
                />
              </div>
              <div
                class="project-type-item"
                @click="
                  () => {
                    projectForm.pay_type.hour = !projectForm.pay_type.hour;
                  }
                "
              >
                <img :src="icons.hour" />
                <span style="font-size: 12px; color: #424242; margin: 0px 20px"
                  >按小时</span
                >
                <Icon
                  :size="20"
                  v-show="projectForm.pay_type.hour == true"
                  type="ios-checkmark"
                />
              </div>
              <div
                class="project-type-item"
                @click="
                  () => {
                    projectForm.pay_type.task = !projectForm.pay_type.task;
                  }
                "
              >
                <img :src="icons.task" />
                <span style="font-size: 12px; color: #424242; margin: 0px 20px"
                  >基于任务</span
                >
                <Icon
                  :size="20"
                  v-show="projectForm.pay_type.task == true"
                  type="ios-checkmark"
                />
              </div>
              <div
                class="project-type-item"
                @click="
                  () => {
                    projectForm.pay_type.schedule = !projectForm.pay_type
                      .schedule;
                  }
                "
              >
                <img :src="icons.schedule" />
                <span style="font-size: 12px; color: #424242; margin: 0px 20px"
                  >定期支付</span
                >
                <Icon
                  :size="20"
                  v-show="projectForm.pay_type.schedule == true"
                  type="ios-checkmark"
                />
              </div>
            </div>

            <Divider />
            <div class="project-row">
              <span class="project-title">附件</span>
              <Upload
                :before-upload="handleBeforeUploadAccessories"
                :show-upload-list="false"
                style="cursor: pointer"
                action
              >
                <Icon type="ios-cloud-upload-outline" :size="30" />
              </Upload>
            </div>
            <div class="project-tag-container">
              <accessories-item
                v-for="item in projectForm.accessories"
                :key="item.file_url"
                :data-source="item"
              />
            </div>
            <div class="tools-container">
              <Button @click="submit" type="primary" size="large">保存</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-model="tagModalVisiable" @on-ok="addTagComplete">
      <Input
        v-model="tag"
        placeholder="请输入标签..."
        style="width: 300px; margin: 30px"
      />
    </Modal>
    <Modal v-model="addressModalVisiable" @on-ok="addAddressComplete">
      <Input
        v-model="address"
        placeholder="请输入地址..."
        style="width: 300px; margin: 30px"
      />
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as icon from "../../common/icon";
import moment from "moment";
import * as oss from "../../utils/ossHelper";
import MyInput from "../../components/Form/MyInput";
import AccessoriesItem from "../../components/Item/AccessoriesItem";
import MyTextArea from "../../components/Form/MyTextArea";
import MySingleImage from "../../components/Form/MySingleImage";
import MyDatePicker from "../../components/Form/MyDatePicker";
export default {
  components: {
    "my-input": MyInput,
    "my-single-image": MySingleImage,
    "my-textarea": MyTextArea,
    "my-datepicker": MyDatePicker,
    "accessories-item": AccessoriesItem,
  },
  data() {
    return {
      tagModalVisiable: false,
      addressModalVisiable: false,

      icons: icon,
      projectForm: {
        employer_id: localStorage.getItem("employer_id"),
        company_id: 0,
        name: "",
        more: "",
        img_url: "",
        tags: [],
        start_time: new Date(),
        end_time: new Date(),
        address_arr: [],
        pay_type: {
          fixed: false,
          hour: false,
          task: false,
          schedule: false,
        },
        accessories: [],
      },
      tag: "",
      address: "",
      companyList: [],
    };
  },
  mounted() {
    this.getCompanyList();
  },
  methods: {
    ...mapActions({
      projectCreateAction: "projectCreate",
      getCompanyListAction: "getCompanyList",
    }),
    getCompanyList() {
      this.getCompanyListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.companyList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    close() {
      this.$router.push({ name: "dashboard" });
    },
    submit() {
      if (this.projectForm.company_id == 0) {
        this.$Message.error("请关联一个公司实体");
        return;
      }
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          let params = Object.assign(this.projectForm, {
            start_time: moment(this.projectForm.start_time).format(
              "YYYY-MM-DD 00:00:00"
            ),
            end_time: moment(this.projectForm.end_time).format(
              "YYYY-MM-DD 23:59:59"
            ),
          });
          this.projectCreateAction(this.projectForm)
            .then(() => {
              this.$router.push({ name: "dashboard" });
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    addTag() {
      this.tag = "";
      this.tagModalVisiable = true;
    },
    addTagComplete() {
      this.projectForm.tags.push(this.tag);
    },
    deleteTag(index) {
      this.projectForm.tags.splice(index, 1);
    },
    addAddress() {
      this.address = "";
      this.addressModalVisiable = true;
    },
    addAddressComplete() {
      this.projectForm.address_arr.push(this.address);
    },
    deleteAddress(index) {
      this.projectForm.address_arr.splice(index, 1);
    },
    /** 附件上传 */
    handleBeforeUploadImg(file) {
      this.$Spin.show();
      oss.upload(file, "project").then((result) => {
        this.projectForm.img_url = result.fullPath;
        this.$Spin.hide();
      });
      return false;
    },
    handleBeforeUploadAccessories(file) {
      this.$Spin.show();
      oss.upload(file, "project-accessories").then((result) => {
        this.projectForm.accessories.push({
          file_name: result.name,
          file_url: result.fullPath,
          more: "",
          size: file.size,
        });
        this.$Spin.hide();
      });
      return false;
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.projectForm.name == "") {
          reject("请填写项目名称");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.project-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #f7f7f7;
  overflow: hidden;
}
.project-header-container {
  background-color: #313f4a;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
.project-header-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.project-header-title {
  margin-left: 40px;
  color: #fff;
  font-size: 15px;
}
.project-main-container {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}
.project-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 340px;
  z-index: 1;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.project-scroll-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  z-index: 2;
}
.project-form-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
  padding: 100px;
}
.project-form-main-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  border-radius: 13px;
  padding: 60px 130px;
  margin-top: 40px;
  margin-bottom: 100px;
}
.project-title {
  font-size: 23px;
  font-weight: bold;
  color: #010101;
}
.project-sub-title {
  font-size: 13px;
  font-weight: bold;
  color: #010101;
}
.project-desc {
  font-size: 14px;
  color: #757575;
  margin-top: 10px;
  margin-bottom: 30px;
}
.project-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.project-form-image {
  margin-top: 8px;
  margin-left: 60px;
  width: 190px;
  height: 198px;
}
.project-tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
}
.project-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #f4f4f4;
}
.project-time-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.project-type-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.project-type-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  background-color: #e7edf5;
  border-radius: 8px;
  height: 40px;
}
</style>